const TailArrowIcon = () => {
  return (
    <svg
      width="19"
      height="26"
      viewBox="0 0 19 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7754 1.30024C10.7754 0.692465 10.2827 0.199768 9.67496 0.199768L8.34719 0.199768C7.73942 0.199768 7.24672 0.692464 7.24672 1.30024L7.24672 19.5039L2.81642 15.0736C2.38666 14.6439 1.68988 14.6439 1.26012 15.0736L0.32232 16.0114C-0.10744 16.4412 -0.10744 17.138 0.32232 17.5677L8.23251 25.4779C8.66228 25.9077 9.35905 25.9077 9.78881 25.4779L10.7266 24.5401C10.7336 24.5332 10.7404 24.5261 10.7472 24.519L17.699 17.5672C18.1288 17.1374 18.1288 16.4406 17.699 16.0109L16.7602 15.072C16.3304 14.6422 15.6336 14.6422 15.2039 15.072L10.7754 19.5004L10.7754 1.30024Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TailArrowIcon;
