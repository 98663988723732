import axios, {
  type AxiosError,
  type AxiosResponse,
  AxiosRequestConfig,
} from "axios";
import { getCookie, hasCookie } from "cookies-next";

import { LoginResponse } from "../utils/apiTypes/auth.types";
import { AxiosMethod, AxiosParams } from "./axios.type";
import { removeAuth, setToken, storeAuthResult } from "./token.util";

axios.defaults.baseURL = process.env.NEXT_PUBLIC_BASE_API;

setToken();

export interface RefreshToken {
  refresh: string;
}

export async function refreshToken() {
  if (hasCookie("refreshToken")) {
    const refreshToken = getCookie("refreshToken") as string;
    try {
      const res = await axios.post<RefreshToken, LoginResponse>(
        "/auth/refresh-token",
        { refreshToken: refreshToken }
      );
      storeAuthResult(res);
      return true;
    } catch (e) {}
  }
  removeAuth();
  return false;
}

export async function sendRawRequest<T, R>(
  method: AxiosMethod,
  url: string,
  data?: T,
  config?: AxiosRequestConfig<T>
) {
  if (method === "get") return axios.get<T, AxiosResponse<R>>(url, config);
  return axios[method]<T, AxiosResponse<R>>(url, data, config);
}

export async function sendRequest<T = unknown, R = unknown>(
  request: AxiosParams<T>
) {
  const { method, url, data, config } = request;
  try {
    return await sendRawRequest<T, R>(method, url, data, config);
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 401) {
      const success = await refreshToken();
      if (success) return sendRawRequest<T, R>(method, url, data, config);
    }
    throw error;
  }
}
