const EmailIcon = () => {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8786 0H0.588095C0.262805 0 0 0.264062 0 0.590909V12.4091C0 12.7359 0.262805 13 0.588095 13H15.8786C16.2039 13 16.4667 12.7359 16.4667 12.4091V0.590909C16.4667 0.264062 16.2039 0 15.8786 0ZM14.3936 2.01094L8.59538 6.54432C8.45203 6.65696 8.25171 6.65696 8.10836 6.54432L2.30827 2.01094C2.28641 1.99398 2.27037 1.97058 2.26241 1.94401C2.25445 1.91743 2.25496 1.88903 2.26388 1.86276C2.27279 1.8365 2.28967 1.81369 2.31213 1.79755C2.33459 1.7814 2.36152 1.77272 2.38914 1.77273H14.3128C14.3404 1.77272 14.3673 1.7814 14.3898 1.79755C14.4122 1.81369 14.4291 1.8365 14.438 1.86276C14.4469 1.88903 14.4475 1.91743 14.4395 1.94401C14.4315 1.97058 14.4155 1.99398 14.3936 2.01094Z"
        fill="#fff"
      />
    </svg>
  );
};

export default EmailIcon;
