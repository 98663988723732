import { useRouter } from "next/router";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import { useAuth } from "~/src/contexts/providers/auth";
import { MOVIES_HOME_PAGE, MOVIES_WELCOME } from "~/src/utils/constants/page";

import ArrowIcon from "../../icons/arrow.icon";
import styles from "./index.module.scss";

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { line?: boolean };

const AccessVideoPlatform = ({ line = false, ...props }: Props) => {
  const router = useRouter();
  const { user } = useAuth();
  return (
    <div
      {...props}
      className={`${styles.accessVideoPlatform} ${line ? styles.addLine : 0} ${
        props.className ?? ""
      }`}
    >
      <div
        onClick={() => {
          if (!!user && user.id !== 0) router.push(MOVIES_HOME_PAGE);
          else router.push(MOVIES_WELCOME);
        }}
      >
        Accéder à notre <br /> <b>plateforme vidéo</b>
      </div>
      <ArrowIcon />
    </div>
  );
};

export default AccessVideoPlatform;
