import Link from "next/link";
import { useRouter } from "next/router";

import ArrowIcon from "~/src/components/icons/arrow.icon";
import SearchIcon from "~/src/components/icons/search.icon";
import AccessVideoPlatform from "~/src/components/ui/AccessVideoPlatform";
import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { categoriesList } from "~/src/utils/constants/categories";
import {
  CATEGORY_PAGE,
  HOME_PAGE,
  SEARCH_PAGE,
  SUBSCRIPTION_PAGE,
} from "~/src/utils/constants/page";

import styles from "./index.module.scss";

type Props = {
  subNavbarVisible: boolean;
  handleSetNavbarVisible: () => void;
  categorySelected: boolean;
};

type NavbarItem = {
  name: string;
  href: string;
};

const MainNavbar = ({
  subNavbarVisible = false,
  handleSetNavbarVisible,
  categorySelected,
}: Props) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";
  let index = -1;
  const router = useRouter();

  const navbarElements: NavbarItem[] =
    categorySelected && !isDesktop
      ? []
      : [
          { name: "À la une", href: HOME_PAGE },
          // { name: "Vidéos", href: VIDEO_PAGE },
        ];

  if (isDesktop)
    navbarElements.push(
      ...categoriesList.map(({ name, href }) => ({
        name,
        href: `${CATEGORY_PAGE}/${href}`,
      })),
      {
        name: "À propos",
        href: `${SUBSCRIPTION_PAGE}/#about`,
      }
    );

  if (categorySelected && !isDesktop) {
    categoriesList.map(({ name, href }, i) => {
      if (router.asPath.includes(CATEGORY_PAGE + href) || index == i) {
        navbarElements.push({
          name,
          href: `${CATEGORY_PAGE}/${href}`,
        });
        if (index === -1) {
          index = i + 1;
        }
      }
    });
  }

  return (
    <nav className={`${styles.mainNavbar} container`}>
      <Link href={SEARCH_PAGE}>
        <div style={{ cursor: "pointer" }}>
          <SearchIcon />
        </div>
      </Link>
      {navbarElements.map(({ name, href }, i) => {
        const isCurrentRoute =
          (router.asPath.includes(href) && i != 0) ||
          (router.asPath == "/" && i == 0);
        return (
          <Link key={href} href={href}>
            <span
              className={[
                styles.route,
                isCurrentRoute || (categorySelected && i == 0 && !isDesktop)
                  ? styles.active
                  : undefined,
              ].join(" ")}
            >
              {name}
            </span>
          </Link>
        );
      })}

      {isDesktop ? (
        <>
          <div className={styles.verticalLine} />
          <AccessVideoPlatform />
        </>
      ) : (
        <div
          onClick={handleSetNavbarVisible}
          className={`${styles.subNavBarButton} ${
            subNavbarVisible ? styles.subNavbarVisible : ""
          }`}
        >
          <ArrowIcon />
        </div>
      )}
    </nav>
  );
};

export default MainNavbar;
