const YoutubeIcon = () => {
  return (
    <svg
      width="37"
      height="26"
      viewBox="0 0 37 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.2252 4.53273C36.0158 3.78548 35.6077 3.10895 35.0445 2.57507C34.4653 2.02478 33.7555 1.63114 32.982 1.43131C30.0872 0.668288 18.4892 0.668288 18.4892 0.668288C13.6542 0.613278 8.8203 0.855228 4.01491 1.39277C3.24139 1.60737 2.5329 2.0098 1.95244 2.56428C1.3821 3.11304 0.968988 3.78974 0.753183 4.53119C0.23478 7.32391 -0.017096 10.1596 0.000951363 13C-0.0175461 15.8378 0.233712 18.6725 0.753183 21.4687C0.964363 22.2071 1.37593 22.8807 1.94781 23.4249C2.5197 23.969 3.23185 24.3621 4.01491 24.5702C6.94831 25.3316 18.4892 25.3316 18.4892 25.3316C23.3304 25.3867 28.1704 25.1448 32.982 24.6072C33.7555 24.4073 34.4653 24.0137 35.0445 23.4634C35.6075 22.9296 36.0151 22.253 36.2237 21.5057C36.7556 18.7141 37.0143 15.8772 36.996 13.0354C37.036 10.1816 36.7777 7.33289 36.2252 4.53273ZM14.802 18.2779V7.72355L24.4516 13.0015L14.802 18.2779Z"
        fill="#fff"
      />
    </svg>
  );
};

export default YoutubeIcon;
