import { SiTelegram, SiTiktok } from "react-icons/si";

import FacebookIcon from "~/src/components/icons/facebook.icon";
import InstagramIcon from "~/src/components/icons/instagram.icon";
import TwitterIcon from "~/src/components/icons/twitter.icon";
import Youtube from "~/src/components/icons/youtube.icon";

import styles from "./index.module.scss";

const footerSocials: { href: string; icon: JSX.Element }[] = [
  {
    href: "https://www.facebook.com/people/Omerta/100085940151972/",
    icon: <FacebookIcon />,
  },
  {
    href: "https://twitter.com/omerta_officiel?s=11&t=ZnQ42Wj8JxI-_kWtdIy4Ig",
    icon: <TwitterIcon />,
  },
  {
    href: "https://www.youtube.com/channel/UC3lacWBPVK-JfmGyyHx4AAw",
    icon: <Youtube />,
  },
  {
    href: "https://instagram.com/omertamediaofficiel?igshid=YmMyMTA2M2Y=",
    icon: <InstagramIcon />,
  },
  {
    href: "https://t.me/omertamedia",
    icon: <SiTelegram className={styles.telegram} />,
  },
  {
    href: "https://www.tiktok.com/@omerta_officiel?_t=8WUlS4lxW1m&_r=1",
    icon: <SiTiktok className={styles.tiktok} />,
  },
];

const FooterSocials = () => {
  return (
    <div className={styles.footerSocials}>
      {footerSocials.map(({ href, icon }, i) => (
        <a key={i} href={href} target="_blank" rel="noreferrer">
          {icon}
        </a>
      ))}
    </div>
  );
};

export default FooterSocials;
