const SearchIcon = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0434 11.5625C15.9564 8.67883 15.6422 4.75357 13.1007 2.21212C10.2007 -0.687894 5.49885 -0.687894 2.59884 2.21212C-0.301175 5.11213 -0.301175 9.81399 2.59884 12.714C5.29323 15.4084 9.54301 15.5994 12.458 13.2871L16.2554 17.0845C16.7124 17.5415 17.4534 17.5415 17.9104 17.0845C18.3674 16.6275 18.3674 15.8865 17.9104 15.4295L14.0434 11.5625ZM11.4457 3.86717C13.4316 5.85312 13.4316 9.073 11.4457 11.059C9.45971 13.0449 6.23984 13.0449 4.25389 11.059C2.26793 9.073 2.26793 5.85312 4.25389 3.86717C6.23984 1.88121 9.45971 1.88121 11.4457 3.86717Z"
        fill="#fff"
      />
    </svg>
  );
};

export default SearchIcon;
