import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { Breakpoint } from "~/src/types/general.types";

type BreakpointKey = keyof typeof Breakpoint;

const BreakpointContext = createContext<BreakpointKey>("XS");

const BreakpointProvider: FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren) => {
  const [breakpoint, setBreakpoint] = useState<BreakpointKey>("XS");

  const calcInnerWidth = () => {
    const width = window.innerWidth;
    switch (true) {
      case width > Breakpoint.XXL:
        setBreakpoint("XXL");
        break;
      case width > Breakpoint.XL:
        setBreakpoint("XL");
        break;
      case width > Breakpoint.LG:
        setBreakpoint("LG");
        break;
      case width > Breakpoint.MD:
        setBreakpoint("MD");
        break;
      case width > Breakpoint.SM:
        setBreakpoint("SM");
        break;
      default:
        setBreakpoint("XS");
    }
  };

  useEffect(() => {
    calcInnerWidth();
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
};

export const useBreakpoint = () => useContext(BreakpointContext);

export default BreakpointProvider;
