import { sendRawRequest } from "../clients/axios";

export interface NewsletterSubscriptionInput {
  email: string;
}

export default async function subscribedToNewsletter(
  input: NewsletterSubscriptionInput
) {
  return sendRawRequest<NewsletterSubscriptionInput, never>(
    "post",
    "/newsletter/subscribe",
    input
  );
}
