import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { User } from "~/src/types/models/user.type";

type ContextData = {
  user?: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
};
const AuthContext = createContext<ContextData>({
  setUser: () => {},
});

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User>();

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
