export enum Breakpoint {
  XS = 0,
  SM = 320,
  MD = 480,
  LG = 768,
  XL = 1024,
  XXL = 1200,
}

export type UrlItem = {
  name: string;
  href: string;
};
