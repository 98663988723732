import React, { useEffect } from "react";

export const useOutsideClick = <T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  onOutsideClick: () => void
) => {
  useEffect(() => {
    // handle click outside
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    }
    // bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};
