import Link from "next/link";
import Router, { useRouter } from "next/router";

import DiscoverIcon from "~/src/components/icons/discover.icon";
import PersonIcon from "~/src/components/icons/person.icon";
import AccessVideoPlatform from "~/src/components/ui/AccessVideoPlatform";
import { categoriesList } from "~/src/utils/constants/categories";
import {
  CATEGORY_PAGE,
  HOME_PAGE,
  MAGAZINES_LIST_PAGE,
  SUBSCRIPTION_PAGE,
} from "~/src/utils/constants/page";

import PdfDownloadWidget from "../../../ui/PdfDownloadWidget";
import MainButton from "../../MainButton";
import styles from "./index.module.scss";

type Props = {
  visible: boolean;
  categorySelected: boolean;
  isSignedIn: boolean;
  pdfPath?: string;
};

const SubNavbar = ({
  visible = false,
  categorySelected,
  isSignedIn = false,
  pdfPath,
}: Props) => {
  const router = useRouter();
  let index: number;
  return (
    <nav className={`${styles.subNavbar} ${visible ? styles.visible : ""}`}>
      {pdfPath && <PdfDownloadWidget pdfPath={pdfPath} />}
      <MainButton
        className={styles.btn}
        onClick={() => {
          Router.push(MAGAZINES_LIST_PAGE);
        }}
      >
        <DiscoverIcon /> Découvrez notre magazine
      </MainButton>
      {!isSignedIn && (
        <MainButton theme="noFill">
          <PersonIcon /> &nbsp; Se connecter
        </MainButton>
      )}
      {categorySelected && (
        <>
          <Link href={HOME_PAGE}>
            <div style={{ cursor: "pointer" }}>À la une</div>
          </Link>
          {/* <Link href={VIDEO_PAGE}>
            <div style={{ cursor: "pointer" }}>{"Vidéos"}</div>
          </Link> */}
        </>
      )}
      {categoriesList.map(({ name, href }, i) => {
        const isCurrentRoute = router.asPath.includes(CATEGORY_PAGE + href);
        if (isCurrentRoute) {
          index = i + 1;
        }
        return isCurrentRoute || index === i ? (
          <div key={i}></div>
        ) : (
          <Link
            key={`sub-navbar-element-${i}`}
            href={`${CATEGORY_PAGE}/${href}`}
          >
            <div
              className={isCurrentRoute ? styles.active : undefined}
              style={{ cursor: "pointer" }}
            >
              {name}
            </div>
          </Link>
        );
      })}
      {
        <Link href={SUBSCRIPTION_PAGE}>
          <div style={{ cursor: "pointer" }}>À propos</div>
        </Link>
      }
      <AccessVideoPlatform line={true} />
    </nav>
  );
};

export default SubNavbar;
