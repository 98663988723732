export const HOME_PAGE = "/";
export const LOGIN_PAGE = "/connexion";
export const REGISTER_PAGE = "/inscription";
export const FORGOT_PASSWORD_PAGE = "/mot-de-passe-oublie";
export const PROFILE_PAGE = "/profil";
export const SUBSCRIPTION_PAGE = "/abonnement";
export const CONFIRMATION_PAGE = "/confirmation";
export const PAYMENT_PAGE = "/paiement";
export const STUDENT_PAYMENT_PAGE = "/paiement-etudiant";
export const SEARCH_PAGE = "/recherche";
export const ACCOUNT_PAGE = "/profil/mon-compte";
export const ARTICLE_PAGE = "/article";
export const CATEGORY_PAGE = "/categorie";
export const VIDEO_PAGE = "/video";
export const ALL_VIDEOS_PAGE = "/videos/all";
export const MOVIES_WELCOME = "/welcome";
export const MOVIE_PAGE = "/movie";
export const MOVIES_HOME_PAGE = "/movies-home";
export const MOVIES_SEARCH_PAGE = "/movies-search";
export const MOVIE_CATEGORY_PAGE = "/movie-category";
export const PRODUCER_MOVIES_PAGE = "/producer-movies";
export const MOVIES_PERSONAL_PAGE = "/movies-personal";
export const MAGAZINES_LIST_PAGE = "/magazine";
export const CART_PAGE = "/mon-panier";
export const CART_SUCCESS_PAGE = "/mon-panier/succes";
export const PRIVACY_POLICY_PAGE = "/politique-de-confidentialite";
