import Link from "next/link";
import { useRouter } from "next/router";
import { AiFillHeart, AiFillHome } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useAuth } from "~/src/contexts/providers/auth";
import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import {
  HOME_PAGE,
  LOGIN_PAGE,
  MOVIES_HOME_PAGE,
  MOVIES_PERSONAL_PAGE,
  MOVIES_SEARCH_PAGE,
  PROFILE_PAGE,
} from "~/src/utils/constants/page";

import PersonIcon from "../../icons/person.icon";
import AccessPressPlatform from "../../ui/AccessPressPlatform";
import MainButton from "../MainButton";
import styles from "./index.module.scss";

const MoviesNav = () => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint == "XL" || breakpoint == "XXL";
  const { user } = useAuth();
  const router = useRouter();

  return (
    <header className={styles.header}>
      <div className={styles.leftHeader}>
        <Link href={HOME_PAGE}>
          <LazyLoadImage src="/logo.png" alt="logo" className={styles.logo} />
        </Link>
        {isDesktop && <AccessPressPlatform />}
      </div>
      <div className={!!user && user.id !== 0 ? styles.icons : styles.connect}>
        <Link href={MOVIES_HOME_PAGE}>
          <AiFillHome
            className={
              router.pathname == MOVIES_HOME_PAGE ? styles.active : styles.icon
            }
          />
        </Link>
        <Link href={MOVIES_SEARCH_PAGE}>
          <FiSearch
            className={
              router.pathname == MOVIES_SEARCH_PAGE
                ? styles.active
                : styles.icon
            }
          />
        </Link>
        {!!user && user.id !== 0 && (
          <Link href={MOVIES_PERSONAL_PAGE}>
            <AiFillHeart
              className={
                router.pathname == MOVIES_PERSONAL_PAGE
                  ? styles.active
                  : styles.icon
              }
            />
          </Link>
        )}

        {!!user && user.id !== 0 ? (
          <Link href={PROFILE_PAGE}>
            <div>
              {user?.profilePicturePath ? (
                <LazyLoadImage
                  className={styles.profileImg}
                  src={user.profilePicturePath}
                  alt="profile"
                />
              ) : (
                <PersonIcon />
              )}
            </div>
          </Link>
        ) : (
          <MainButton
            href={LOGIN_PAGE}
            theme="noFill"
            onClick={() => {
              localStorage.setItem("movies", "movies");
              router.push(LOGIN_PAGE);
            }}
          >
            Se connecter
          </MainButton>
        )}
      </div>
    </header>
  );
};
export default MoviesNav;
