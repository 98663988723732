import { useRouter } from "next/router";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import { HOME_PAGE } from "~/src/utils/constants/page";

import ArrowIcon from "../../icons/arrow.icon";
import styles from "./index.module.scss";

type Props = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { line?: boolean };

const AccessPressPlatform = ({ line = false, ...props }: Props) => {
  const router = useRouter();
  return (
    <div
      {...props}
      className={`${styles.accessPressPlatform} ${line ? styles.addLine : 0} ${
        props.className ?? ""
      }`}
    >
      <ArrowIcon />
      <div
        onClick={() => {
          router.push(HOME_PAGE);
        }}
      >
        Accéder à notre <br /> <b>plateforme presse</b>
      </div>
    </div>
  );
};

export default AccessPressPlatform;
