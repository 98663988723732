import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { NewspaperQuantity } from "~/src/types/models/newspaper.types";

type ContextData = {
  cart?: NewspaperQuantity[];
  setCart: Dispatch<SetStateAction<NewspaperQuantity[] | undefined>>;
};
const CartContext = createContext<ContextData>({
  setCart: () => {},
});

const CartProvider = ({ children }: PropsWithChildren) => {
  const [cart, setCart] = useState<NewspaperQuantity[]>();

  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);

export default CartProvider;
