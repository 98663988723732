import { Language } from "./language.type";
import { Producer } from "./producer.type";
import { Season } from "./season.type";
import { Serie } from "./serie.type";

export enum MovieCategoryEnum {
  DOCUMENTARY = "DOCUMENTARY",
  SHOW = "SHOW",
  CONFERENCE = "CONFERENCE",
  MASTERCLASS = "MASTERCLASS",
  SERIE = "SERIE",
  PODCAST = "PODCAST",
}

export enum MovieTypeEnum {
  VIDEO = "VIDEO",
  PODCAST = "PODCAST",
}

export interface MovieGenre {
  id: number;
  name: string;
}

export interface Movie {
  id: number;
  title: string;
  type: MovieTypeEnum;
  description: string;
  newRelease: boolean;
  releaseDate: string;
  pgRating: string;
  category: MovieCategoryEnum;
  genres: MovieGenre[];
  country: string;
  trailerPath?: string;
  previewPath?: string;
  posterPath: string;
  moviePath: string;
  highlightPicturePath?: string;
  highlighted: boolean;
  languages: Language[];
  duration: number;
  welcomeVideo: boolean;
  producer: Producer;
  producerId: number;
  season?: Season;
  seasonId?: number;
  serie?: Serie;
  serieId?: number;
  isFree: boolean;
  suggestedMovies?: Movie[];
  embbed: string;
  vimeoId?: string;
  podcastPath?: string;
  previewPathWeb?: string;
  trailerPathWeb?: string;
}
