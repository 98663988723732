import { MovieCategoryEnum } from "~/src/types/dataTypes/movie.type";
import { CategoryEnum } from "~/src/types/models/article";

export const categoriesList = [
  { name: "Enquêtes", href: "enquetes" },
  { name: "Géopolitique", href: "geopolitique" },
  { name: "Société", href: "societe" },
  { name: "Économie", href: "economie" },
  { name: "Politique", href: "politique" },
  { name: "Culture", href: "culture" },
];

export const categoryNames: Record<CategoryEnum, string> = {
  [CategoryEnum.INVESTIGATIONS]: "Enquêtes",
  [CategoryEnum.GEOPOLITICS]: "Géopolitique",
  [CategoryEnum.SOCIETY]: "Société",
  [CategoryEnum.ECONOMY]: "Économie",
  [CategoryEnum.POLITICS]: "Politique",
  [CategoryEnum.CULTURE]: "Culture",
  [CategoryEnum.DEFAULT]: "",
};

export const movieCategoriesList = [
  { name: "Documentaires", href: "documentaires" },
  { name: "Show", href: "show" },
  { name: "Conférences", href: "conférences" },
  { name: "Masterclasses", href: "masterclasses" },
  { name: "Reportages", href: "reportages" },
  { name: "Podcasts", href: "podcasts" },
];

export const movieCategoryNames: Record<MovieCategoryEnum, string> = {
  [MovieCategoryEnum.DOCUMENTARY]: "Documentaires",
  [MovieCategoryEnum.SHOW]: "Bonus",
  [MovieCategoryEnum.CONFERENCE]: "Entretiens",
  [MovieCategoryEnum.MASTERCLASS]: "Décryptages",
  [MovieCategoryEnum.SERIE]: "Reportages",
  [MovieCategoryEnum.PODCAST]: "Podcasts",
};
