import { Movie, MovieGenre } from "../dataTypes/movie.type";
import { MovieRating } from "../dataTypes/movieRating.type";
import { Stream } from "../dataTypes/stream.type";
import { Comment } from "./comment.type";
import { NewspaperSubscription } from "./newspaper.types";
import { Order } from "./order.type";
import { StripeCardStatus } from "./payment.type";
import { ReferralCode } from "./referralCode.type";
import { Subscription } from "./subscription.type";

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  birthDate: Date;
  phoneNumber: string;
  referredBy: boolean;
  studentDocument?: string;
  comments: Comment[];
  likedComments: Comment[];
  subscriptionName: string;
  subscriptionType: SubscriptionEnum;
  subscriptionFrequency: SubscriptionFrequencyEnum;
  subscription?: Subscription;
  newspaperSubscription?: NewspaperSubscription;
  profilePicturePath?: string;
  createdAt: Date;
  canEditCredentials: boolean;
  provider: string;
  updatedAt?: Date;
  referralCode?: ReferralCode;
  stripeCardStatus: StripeCardStatus;
  hasValidSubscription: boolean;
  sharedWith: User[];
  sharedFrom?: User;
  sharedFromId: number;
  ratings: MovieRating[];
  favorites: Movie[];
  streams: Stream[];
  audioPreferences?: string;
  subtitlePreference?: string;
  themePreference: string;
  movieGenrePreferences: MovieGenre[];
  usedPromoCode: boolean;
  orders: Order[];
}

export interface UserInputData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  password?: string;
  confirmPassword?: string;
  birthDate?: Date;
  referral?: number;
  code?: string;
}

export enum SubscriptionEnum {
  PREMIUM = "PREMIUM",
  FULL = "FULL",
  FAMILY = "FAMILY",
  BIENFAITEUR = "BIENFAITEUR",
  SUPER_BIENFAITEUR = "SUPER_BIENFAITEUR",
  MAGAZINE = "MAGAZINE",
  STUDENT = "STUDENT",
}
export enum SubscriptionFrequencyEnum {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export const UnauthenticatedUser: User = {
  id: 0,
  subscriptionName: "Pas d'abonnement",
  email: "",
  firstName: "",
  lastName: "",
  birthDate: new Date(Date.now()),
  phoneNumber: "",
  referredBy: false,
  comments: [],
  likedComments: [],
  canEditCredentials: false,
  createdAt: new Date(Date.now()),
  provider: "",
  subscriptionType: SubscriptionEnum.BIENFAITEUR,
  subscriptionFrequency: SubscriptionFrequencyEnum.MONTHLY,
  stripeCardStatus: "NONE",
  hasValidSubscription: false,
  sharedFromId: 0,
  ratings: [],
  favorites: [],
  streams: [],
  themePreference: "LIGHT",
  movieGenrePreferences: [],
  sharedWith: [],
  usedPromoCode: false,
  orders: [],
};
