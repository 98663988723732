import { Comment } from "./comment.type";
import { Paragraph } from "./paragraph.type";

export enum CategoryEnum {
  INVESTIGATIONS = "INVESTIGATIONS",
  GEOPOLITICS = "GEOPOLITICS",
  SOCIETY = "SOCIETY",
  ECONOMY = "ECONOMY",
  POLITICS = "POLITICS",
  CULTURE = "CULTURE",
  DEFAULT = "",
}

export enum FeatureEnum {
  DESKTOP_ARTICLE = "DESKTOP_ARTICLE",
  MAIN_TOP_ARTICLE = "MAIN_TOP_ARTICLE",
  SUGGESTED_ARTICLE = "SUGGESTED_ARTICLE",
  FEAT_ARTICLE_A = "FEAT_ARTICLE_A",
  FEAT_VIDEO_A = "FEAT_VIDEO_A",
  FEAT_ARTICLE_B = "FEAT_ARTICLE_B",
  FEAT_VIDEO_B = "FEAT_VIDEO_B",
  FEAT_ARTICLE_C = "FEAT_ARTICLE_C",
  FEAT_ARTICLE_D = "FEAT_ARTICLE_D",
  MAIN_BOTTOM_ARTICLE = "MAIN_BOTTOM_ARTICLE",
  VIDEO_GROUP = "VIDEO_GROUP",
  BOTTOM_ARTICLE_A = "BOTTOM_ARTICLE_A",
  BOTTOM_ARTICLE_B = "BOTTOM_ARTICLE_B",
  BOTTOM_ARTICLE_C = "BOTTOM_ARTICLE_C",
  CATEGORY_MAIN = "CATEGORY_MAIN",
  CATEGORY_A = "CATEGORY_A",
  CATEGORY_B = "CATEGORY_B",
  CATEGORY_C = "CATEGORY_C",
  CATEGORY_VIDEO = "CATEGORY_VIDEO",
  DEFAULT = "DEFAULT",
}

export interface LightArticle {
  id: number;
  createdAt: Date;
  slug: string;
  title: string;
}

export type Article = {
  id: number;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  paragraphs: Paragraph[];
  features?: FeatureEnum[];
  category: CategoryEnum;
  title: string;
  header?: string;
  author: string;
  media?: Media;
  publishedAt: Date;
  comments: Comment[];
  freeArticle: boolean;
  createdAt: Date;
};

export type Media = {
  id?: number;
  path: string;
  mainImagePath?: string;
  squareImagePath?: string;
  type: "video" | "image";
  caption?: string;
  credit?: string;
};

export type OptionalArticle = {
  id?: number;
  slug?: string;
  metaTitle?: string;
  metaDescription?: string;
  paragraphs?: Paragraph[];
  features?: FeatureEnum[];
  category: CategoryEnum;
  title?: string;
  header?: string;
  author?: string;
  media?: Media;
  publishedAt?: Date;
  comments?: Comment[];
  freeArticle?: boolean;
};
