const PersonIcon = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C8.93 0 10.5 1.57 10.5 3.5C10.5 5.43 8.93 7 7 7C5.07 7 3.5 5.43 3.5 3.5C3.5 1.57 5.07 0 7 0ZM14 13H0V12.77C0 12.15 0.28 11.57 0.76 11.19C2.47 9.82 4.64 9 7 9C9.36 9 11.53 9.82 13.24 11.19C13.72 11.57 14 12.16 14 12.77V13Z"
        fill="#fff"
      />
    </svg>
  );
};

export default PersonIcon;
