import { UrlItem } from "~/src/types/general.types";
import { PRIVACY_POLICY_PAGE } from "~/src/utils/constants/page";

import styles from "./index.module.scss";

const legalItems: UrlItem[] = [
  {
    href: "/termsOfService/Mentions_légales.pdf",
    name: "Mentions légales",
  },
  {
    href: "/termsOfService/Conditions_Generales_d_abonnement_(CGV).pdf",
    name: "CGV",
  },
  {
    href: PRIVACY_POLICY_PAGE,
    name: "Politique de confidentialité",
  },
  {
    href: "/termsOfService/Conditions_d_utilisation.pdf",
    name: "Conditions d’utilisation",
  },
];

const FooterLegalInfo = () => {
  return (
    <div className={styles.footerLegalInfo}>
      {legalItems.map(({ name, href }, i) => (
        <a
          key={`footer-legal-info-${i}`}
          href={`${href}`}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      ))}
    </div>
  );
};

export default FooterLegalInfo;
