import PdfIcon from "../../icons/pdf.icon";
import TailArrowIcon from "../../icons/tail-arrow.icon";
import styles from "./index.module.scss";

type Props = {
  pdfPath: string;
};
const PdfDownloadWidget = ({ pdfPath }: Props) => {
  function downloadPdfFile() {
    fetch(pdfPath)
      .then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `Newsletter_${new Date().toLocaleDateString(
            "FR"
          )}.pdf`;
          alink.click();
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div
      className={styles.pdfDownloadWidget}
      onClick={() => {
        downloadPdfFile();
      }}
    >
      <div className={styles.label}>
        <PdfIcon />
        Télecharger <br /> notre revue
      </div>
      <div className={styles.arrow}>
        <TailArrowIcon />
      </div>
    </div>
  );
};

export default PdfDownloadWidget;
