import Link from "next/link";
import { Router } from "next/router";
import { useCallback, useEffect } from "react";

import AccessVideoPlatform from "~/src/components/ui/AccessVideoPlatform";
import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { UrlItem } from "~/src/types/general.types";
import { categoriesList } from "~/src/utils/constants/categories";
import {
  CATEGORY_PAGE,
  HOME_PAGE,
  MAGAZINES_LIST_PAGE,
  SUBSCRIPTION_PAGE,
} from "~/src/utils/constants/page";

import styles from "./index.module.scss";

const navJournalItems: UrlItem[] = [
  { name: "À la une", href: HOME_PAGE },
  // { name: "Vidéos", href: VIDEO_PAGE },
];
const categoriesItems: UrlItem[] = categoriesList;

const navInformationItems: UrlItem[] = [
  {
    name: "L’abonnement",
    href: SUBSCRIPTION_PAGE,
  },
  {
    name: "À propos",
    href: `${SUBSCRIPTION_PAGE}/#about`,
  },
  {
    name: "L’équipe",
    href: `${SUBSCRIPTION_PAGE}#team`,
  },
  {
    name: "Nos valeurs",
    href: `${SUBSCRIPTION_PAGE}#values`,
  },
  {
    name: "Notre magazine",
    href: MAGAZINES_LIST_PAGE,
  },
];

const FooterNavigation = () => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint == "XL" || breakpoint == "XXL";
  const resetWindowScrollPosition = useCallback(
    () => window.scrollTo(0, 0),
    []
  );

  useEffect(() => {
    Router.events.on("routeChangeComplete", resetWindowScrollPosition);
  }, []);

  return (
    <nav className={styles.footerNav}>
      <div>
        <h4>LE JOURNAL</h4>
        {navJournalItems.map(({ name, href }, i) => (
          <Link href={href} key={`-nav-item-${i}`}>
            {name}
          </Link>
        ))}
        {categoriesItems.map(({ name, href }, i) => (
          <Link href={`${CATEGORY_PAGE}/${href}`} key={`journal-nav-item-${i}`}>
            {name}
          </Link>
        ))}
        {!isDesktop && <AccessVideoPlatform style={{ marginTop: "3rem" }} />}
      </div>
      <div>
        <h4>INFORMATIONS</h4>
        {navInformationItems.map(({ name, href }) => (
          <Link href={href} key={href}>
            {name}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default FooterNavigation;
