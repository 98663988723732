import { PropsWithChildren } from "react";

import AuthProvider from "./providers/auth";
import BreakpointProvider from "./providers/breakpoint";
import CartProvider from "./providers/cart";

const ContextsProvider = ({ children }: PropsWithChildren) => {
  return (
    <BreakpointProvider>
      <AuthProvider>
        <CartProvider>{children}</CartProvider>
      </AuthProvider>
    </BreakpointProvider>
  );
};

export default ContextsProvider;
