import { useRouter } from "next/router";
import { PropsWithChildren, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useQuery } from "react-query";

import { getPdf } from "../api/newsletter/getPdf";
import { getMe } from "../api/user/getMe";
import { useAuth } from "../contexts/providers/auth";
import { UnauthenticatedUser } from "../types/models/user.type";
import Footer from "./common/Footer";
import Header from "./common/Header";
import MoviesNav from "./common/MoviesNav";

const Main = ({ children }: PropsWithChildren) => {
  const [pdf, setPdf] = useState("");

  const { user, setUser } = useAuth();
  const router = useRouter();

  useQuery("getMe", getMe, {
    onSuccess: (res) => {
      setUser(res);
    },
    onError: () => {
      setUser(UnauthenticatedUser);
    },
  });

  async function getPdfFile() {
    const { data } = await getPdf();
    if (data.pdfPath) {
      setPdf(data.pdfPath);
    }
  }

  useEffect(() => {
    if (user?.hasValidSubscription) getPdfFile();
  }, [user?.hasValidSubscription]);

  const isHeaderVisible = () => {
    return ![
      "/inscription",
      "/connexion",
      "/confirmation",
      "/welcome",
      "/movies-home",
      "/search-movies",
      "/movies-personal",
      "/movies-downloads",
      "/movie/[slug]",
      "/movie-category/[category]",
      "/movies-search",
      "/producer-movies/[slug]",
    ].includes(router.pathname);
  };
  const isFooterVisible = () => {
    return ![
      "/inscription",
      "/connexion",
      "/confirmation",
      "/welcome",
      "/movies-home",
      "/search-movies",
      "/movies-personal",
      "/movies-downloads",
      "/movie/[slug]",
      "/movie-category/[category]",
      "/movies-search",
      "/producer-movies/[slug]",
    ].includes(router.pathname);
  };
  const isMoviesNavVisible = () => {
    return [
      "/movies-home",
      "/search-movies",
      "/movies-personal",
      "/movies-downloads",
      "/movie-category/[category]",
      "/movies-search",
      "/producer-movies/[slug]",
    ].includes(router.pathname);
  };

  return (
    <main id="main">
      {isHeaderVisible() && <Header pdfPath={pdf} />}
      {isMoviesNavVisible() && <MoviesNav />}
      {children}
      <Toaster position="top-center" reverseOrder={false} />
      {isFooterVisible() && <Footer pdfPath={pdf} />}
    </main>
  );
};

export default Main;
