import { Newsletter } from "~/src/types/models/newsletter.type";

import { sendRequest } from "../clients/axios";

export async function getPdf() {
  return sendRequest<undefined, Newsletter>({
    method: "get",
    url: "newsletter/latest",
  });
}
