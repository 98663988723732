const CartIcon = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.81114 21.1064L4.47741 9.10644C4.7824 7.73379 5.99986 6.75714 7.40599 6.75714H20.5929C21.999 6.75714 23.2164 7.73374 23.5214 9.10635L26.1881 21.1063C26.6045 22.9799 25.1788 24.7571 23.2595 24.7571H4.73973C2.82052 24.7571 1.39487 22.98 1.81114 21.1064Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M9.5 10.2429V5.74286C9.5 3.25758 11.5147 1.24286 14 1.24286V1.24286C16.4853 1.24286 18.5 3.25758 18.5 5.74286V10.2429"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CartIcon;
