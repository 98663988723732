import Link from "next/link";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from "react";

import styles from "./index.module.scss";

type Props = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  theme?:
    | "primary"
    | "white"
    | "noFill"
    | "noFillWhite"
    | "black"
    | "bordeaux"
    | "grey";
  href?: string;
};

const MainButton = ({
  theme = "primary",
  children,
  className,
  href,
  ...props
}: PropsWithChildren<Props>) => {
  return href ? (
    <>
      <Link href={href}>
        <button
          className={`${styles.mainButton} ${styles[theme]} ${className}`}
          {...props}
        >
          {children}
        </button>
      </Link>
    </>
  ) : (
    <button
      className={`${styles.mainButton} ${styles[theme]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default MainButton;
