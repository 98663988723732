import { useBreakpoint } from "~/src/contexts/providers/breakpoint";

import AccessVideoPlatform from "../../ui/AccessVideoPlatform";
import PdfDownloadWidget from "../../ui/PdfDownloadWidget";
import NewsletterRegistration from "../NewsletterRegistration";
import styles from "./index.module.scss";
import FooterLegalInfo from "./LegalInfo";
import FooterNavigation from "./Navigation";
import FooterSocials from "./Socials";

type Props = {
  pdfPath?: string;
};
const Footer = ({ pdfPath }: Props) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";

  return (
    <footer className={styles.footer}>
      <div className={`${styles.mainContent} container`}>
        <div className={styles.widgetsContainer}>
          <NewsletterRegistration />
          {pdfPath && <PdfDownloadWidget pdfPath={pdfPath} />}
        </div>
        {!isDesktop && <FooterSocials />}
        <FooterNavigation />
        {isDesktop && (
          <div className={styles.socialsAndVideoPlatform}>
            <FooterSocials />
            <AccessVideoPlatform />
          </div>
        )}
      </div>
      <FooterLegalInfo />
    </footer>
  );
};

export default Footer;
