import "@/styles/globals.scss";
import "@/styles/phoneInput.scss";

import type { AppProps } from "next/app";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";

import ContextsProvider from "~/src/contexts";

import Main from "../components";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <script>
          {`window.axeptioSettings = {clientId:"6396d776f37ce043fd04b0b2",
              cookiesVersion:"omerta media-fr",};
              (function(d,s) {
                var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
                e.async = true; e.src = "//static.axept.io/sdk.js";
                t.parentNode.insertBefore(e, t);
              })(document, "script");`}
        </script>
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3726220109370743"
          crossOrigin="anonymous"
        ></script>
      </Head>
      <QueryClientProvider client={queryClient}>
        <ContextsProvider>
          <Main>
            <Component {...pageProps} />
          </Main>
        </ContextsProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
