import { checkCookies } from "cookies-next";
import Link from "next/link";
import Router from "next/router";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocalStorage } from "usehooks-ts";

import { useAuth } from "~/src/contexts/providers/auth";
import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { NewspaperQuantity } from "~/src/types/models/newspaper.types";
import {
  CART_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  MAGAZINES_LIST_PAGE,
  PROFILE_PAGE,
  SUBSCRIPTION_PAGE,
} from "~/src/utils/constants/page";

import CartIcon from "../../icons/cart.icon";
import DiscoverIcon from "../../icons/discover.icon";
import FilledCartIcon from "../../icons/filled-cart.icon";
import PersonIcon from "../../icons/person.icon";
import PdfDownloadWidget from "../../ui/PdfDownloadWidget";
import MainButton from "../MainButton";
import styles from "./index.module.scss";
import MainNavbar from "./MainNavbar";
import SearchBox from "./SearchBox";
import SubNavbar from "./SubNavbar";

type Props = {
  pdfPath?: string;
};
const Header = ({ pdfPath }: Props) => {
  const router = useRouter();

  const [subNavbarVisible, setSubNavbarVisible] = useState(false);
  const [searchboxVisible, setSearchboxVisible] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [magazines, _setMagazines] = useLocalStorage<NewspaperQuantity[]>(
    "magazines",
    []
  );
  const { user } = useAuth();
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";

  const handleSetNavbarVisible = () => {
    if (searchboxVisible) {
      setSearchboxVisible(false);
      setTimeout(() => {
        setSubNavbarVisible((prev) => !prev);
      }, 500);
      return;
    }
    setSubNavbarVisible((prev) => !prev);
  };

  const handleCloseSearchBox = () => {
    setSearchboxVisible(false);
  };

  useEffect(() => {
    setIsSignedIn(false);
    setSubNavbarVisible(false);
    setSearchboxVisible(false);
  }, [router.asPath]);

  useEffect(() => {
    if (checkCookies("accessToken")) {
      setIsSignedIn(true);
    }
  }, [isSignedIn]);

  const categorySelected = router.asPath !== "/" && router.asPath !== "/video/";

  return (
    <>
      <header className={styles.header}>
        <div className={`${styles.topContent} container`}>
          <div className={styles.buttonsContainer}>
            {isDesktop && pdfPath && <PdfDownloadWidget pdfPath={pdfPath} />}
            {isDesktop && (
              <MainButton
                className={styles.btn}
                onClick={() => {
                  Router.push(MAGAZINES_LIST_PAGE);
                }}
              >
                <DiscoverIcon /> Découvrez notre magazine
              </MainButton>
            )}
          </div>
          <div className={styles.logoContainer}>
            <Link href={HOME_PAGE}>
              <LazyLoadImage
                src="/logo.png"
                alt="logo"
                className={styles.logo}
              />
            </Link>
          </div>
          <div className={styles.connectionsContainer}>
            {isDesktop && !isSignedIn ? (
              <Link href={LOGIN_PAGE}>
                <MainButton theme="noFill">
                  <PersonIcon /> &nbsp; Se connecter
                </MainButton>
              </Link>
            ) : (
              <>
                <Link href={PROFILE_PAGE}>
                  <div>
                    {user?.profilePicturePath ? (
                      <LazyLoadImage
                        className={styles.profileImg}
                        src={user.profilePicturePath}
                        alt="profile"
                      />
                    ) : (
                      <PersonIcon />
                    )}
                  </div>
                </Link>
                {isSignedIn && (
                  <div
                    className={styles.cart}
                    onClick={() => {
                      if (magazines.length === 0) {
                        toast.error("Votre panier est vide");
                        return;
                      } else {
                        router.push(CART_PAGE);
                      }
                    }}
                  >
                    {router.pathname === CART_PAGE ? (
                      <FilledCartIcon />
                    ) : (
                      <CartIcon />
                    )}
                    {magazines.length > 0 && (
                      <div className={styles.cartNumber}>
                        {magazines.length}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {!user?.subscription?.isActive && (
              <MainButton href={SUBSCRIPTION_PAGE} className={styles.sabonner}>
                S’abonner
              </MainButton>
            )}
          </div>
        </div>
        <MainNavbar
          subNavbarVisible={subNavbarVisible}
          handleSetNavbarVisible={handleSetNavbarVisible}
          categorySelected={categorySelected}
        />
      </header>
      <SearchBox
        visible={searchboxVisible}
        handleCloseSearchBox={handleCloseSearchBox}
      />
      {!isDesktop && (
        <SubNavbar
          visible={subNavbarVisible}
          categorySelected={categorySelected}
          isSignedIn={isSignedIn}
          pdfPath={pdfPath}
        />
      )}
    </>
  );
};

export default Header;
