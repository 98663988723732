import { useRef } from "react";

import SearchIcon from "~/src/components/icons/search.icon";
import { useOutsideClick } from "~/src/hooks/outsideClick";

import MainButton from "../../MainButton";
import styles from "./index.module.scss";

type Props = {
  visible?: boolean;
  handleCloseSearchBox: () => void;
};

const SearchBox = ({ visible = false, handleCloseSearchBox }: Props) => {
  const searchBoxRef = useRef(null);

  useOutsideClick(searchBoxRef, handleCloseSearchBox);

  return (
    <div className={styles.searchBoxContainer} ref={searchBoxRef}>
      <div className={`${styles.searchBox} ${visible ? styles.visible : ""}`}>
        <SearchIcon />
        <input type="text" />
        <MainButton>Rechercher</MainButton>
      </div>
    </div>
  );
};

export default SearchBox;
