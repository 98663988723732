const TwitterIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6667 10.0417C25.8889 10.3889 25.0486 10.625 24.1458 10.75C25.0903 10.1944 25.7361 9.38194 26.0833 8.3125C25.1806 8.84028 24.25 9.19444 23.2917 9.375C22.4444 8.45833 21.3819 8 20.1042 8C18.8958 8 17.8646 8.42708 17.0104 9.28125C16.1562 10.1354 15.7292 11.1667 15.7292 12.375C15.7292 12.7778 15.7639 13.1111 15.8333 13.375C14.0417 13.2778 12.3611 12.8264 10.7917 12.0208C9.22222 11.2153 7.88889 10.1389 6.79167 8.79167C6.38889 9.48611 6.1875 10.2222 6.1875 11C6.1875 12.5833 6.81944 13.7986 8.08333 14.6458C7.43056 14.6319 6.73611 14.4514 6 14.1042V14.1458C6 15.1875 6.34722 16.1146 7.04167 16.9271C7.73611 17.7396 8.59028 18.2431 9.60417 18.4375C9.20139 18.5486 8.84722 18.6042 8.54167 18.6042C8.36111 18.6042 8.09028 18.5764 7.72917 18.5208C8.02083 19.3958 8.53819 20.1181 9.28125 20.6875C10.0243 21.2569 10.8681 21.5486 11.8125 21.5625C10.2014 22.8125 8.38889 23.4375 6.375 23.4375C6.01389 23.4375 5.66667 23.4167 5.33333 23.375C7.38889 24.6806 9.625 25.3333 12.0417 25.3333C13.5972 25.3333 15.0556 25.0868 16.4167 24.5938C17.7778 24.1007 18.9444 23.441 19.9167 22.6146C20.8889 21.7882 21.7257 20.8368 22.4271 19.7604C23.1285 18.684 23.6493 17.559 23.9896 16.3854C24.3299 15.2118 24.5 14.0417 24.5 12.875C24.5 12.625 24.4931 12.4375 24.4792 12.3125C25.3542 11.6875 26.0833 10.9306 26.6667 10.0417ZM32 6V26C32 27.6528 31.4132 29.066 30.2396 30.2396C29.066 31.4132 27.6528 32 26 32H6C4.34722 32 2.93403 31.4132 1.76042 30.2396C0.586806 29.066 0 27.6528 0 26V6C0 4.34722 0.586806 2.93403 1.76042 1.76042C2.93403 0.586806 4.34722 0 6 0H26C27.6528 0 29.066 0.586806 30.2396 1.76042C31.4132 2.93403 32 4.34722 32 6Z"
        fill="#fff"
      />
    </svg>
  );
};

export default TwitterIcon;
