import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useMutation } from "react-query";

import subscribedToNewsletter from "~/src/api/newsletter/subscribe";

import EmailIcon from "../../icons/email.icon";
import MainButton from "../MainButton";
import styles from "./index.module.scss";

const NewsletterRegistration = () => {
  const [email, setEmail] = useState("");

  const { mutate } = useMutation(subscribedToNewsletter, {
    onSuccess: () => {
      toast.success("Vous êtes inscrit à la newsletter !");
      setEmail("");
    },
    onError: () => {
      toast.error("Email non valide");
    },
  });

  return (
    <div className={styles.newsletterRegistration}>
      <Toaster position="top-center" reverseOrder={false} />
      <h4>Newsletter</h4>
      <p>Recevez plusieurs fois par semaine nos titres et vidéos à la une</p>
      <input
        type="text"
        placeholder="E-mail"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        value={email}
      />
      <MainButton
        theme="noFill"
        onClick={(e) => {
          e.preventDefault();
          if (email) mutate({ email });
        }}
      >
        <EmailIcon /> Je m’inscris
      </MainButton>
    </div>
  );
};

export default NewsletterRegistration;
