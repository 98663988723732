const PdfIcon = () => {
  return (
    <svg
      width="30"
      height="34"
      viewBox="0 0 30 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.12795 3.25C4.12795 2.21609 4.96609 1.37795 6 1.37795L16.6462 1.37795C17.1427 1.37795 17.6189 1.57518 17.97 1.92626L25.2362 9.19247C25.5873 9.54355 25.7845 10.0197 25.7845 10.5162V30.75C25.7845 31.7839 24.9464 32.6221 23.9125 32.6221H6C4.96609 32.6221 4.12795 31.7839 4.12795 30.75V3.25Z"
        fill="transparent"
        stroke="white"
        strokeWidth="2.25589"
      />
      <path
        d="M16.7607 0.25H17.8887L26.9123 9.27357V10.1759H19.7607C18.1039 10.1759 16.7607 8.83278 16.7607 7.17593V0.25Z"
        fill="#fff"
      />
      <rect x="0.5" y="14.75" width="29" height="15" rx="3.5" fill="white" />
      <path
        d="M3.704 26.25V18.11H7.213C7.895 18.11 8.46333 18.242 8.918 18.506C9.37267 18.77 9.71 19.111 9.93 19.529C10.1573 19.947 10.271 20.3833 10.271 20.838C10.271 21.3 10.1573 21.74 9.93 22.158C9.71 22.576 9.37267 22.9207 8.918 23.192C8.46333 23.456 7.895 23.588 7.213 23.588H5.607V26.25H3.704ZM7.202 19.771H5.607V21.938H7.202C7.59067 21.938 7.88033 21.8243 8.071 21.597C8.26167 21.3697 8.357 21.1203 8.357 20.849C8.357 20.5777 8.26167 20.332 8.071 20.112C7.88033 19.8847 7.59067 19.771 7.202 19.771ZM11.2987 26.25V18.11H14.5217C15.1891 18.11 15.7757 18.2273 16.2817 18.462C16.7877 18.6893 17.2057 18.9973 17.5357 19.386C17.8731 19.7747 18.1261 20.211 18.2947 20.695C18.4634 21.179 18.5477 21.674 18.5477 22.18C18.5477 22.686 18.4634 23.181 18.2947 23.665C18.1261 24.149 17.8731 24.5853 17.5357 24.974C17.2057 25.3627 16.7877 25.6743 16.2817 25.909C15.7757 26.1363 15.1891 26.25 14.5217 26.25H11.2987ZM14.4337 19.782H13.2017V24.556H14.4337C14.9397 24.556 15.3541 24.4387 15.6767 24.204C16.0067 23.9693 16.2487 23.6723 16.4027 23.313C16.5567 22.9463 16.6337 22.5613 16.6337 22.158C16.6337 21.7693 16.5567 21.3917 16.4027 21.025C16.2487 20.6583 16.0067 20.3613 15.6767 20.134C15.3541 19.8993 14.9397 19.782 14.4337 19.782ZM19.6347 26.25V18.11H25.4207V19.76H21.5377V21.707H24.9257V23.291H21.5377V26.25H19.6347Z"
        fill="#000"
      />
      <rect x="0.5" y="14.75" width="29" height="15" rx="3.5" stroke="#fff" />
    </svg>
  );
};

export default PdfIcon;
