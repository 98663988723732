const ArrowIcon = () => {
  return (
    <svg
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.919085 3.04456C0.479586 2.60506 0.479586 1.89249 0.919085 1.45299L1.82089 0.551183C2.26039 0.111684 2.97296 0.111684 3.41246 0.551183L9.58418 6.7229L15.7542 0.552837C16.1937 0.113338 16.9063 0.113337 17.3458 0.552836L18.2465 1.45357C18.686 1.89307 18.686 2.60564 18.2465 3.04514L10.3784 10.9132C9.93895 11.3527 9.22638 11.3527 8.78688 10.9132L7.88614 10.0125C7.87714 10.0035 7.86832 9.99439 7.85969 9.98516L0.919085 3.04456Z"
        fill="#fff"
      />
    </svg>
  );
};

export default ArrowIcon;
